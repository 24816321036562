import { useRouter } from 'next/router'
import { useEffect } from 'react'

const handleRouteChange = (url: string) => {
  return fetch(`${window.location.origin}/api/conversion/PageView`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      url: `${window.location.origin}${url}`,
    }),
  })
}

const PageView = () => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  })

  return null
}

export default PageView
